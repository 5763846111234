<template>
    <div>
        <div class="card card-custom" v-loading="loading">
            <div class="card-body p-0">
                <el-form>
                  <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon-calendar-with-a-clock-time-tools"></i> {{tittle}}</h3>
                  <div class="row mt-5">                    
                    <div class="col-sm-2">
                      <el-form-item label="Employee Name" prop="order_type">
                        <el-select
                            clearable
                            filterable
                            v-model="employee_id"
                            placeholder="Select Employee"
                            style="width: 100%"
                            :allow-create="true"  
                             @change="getEmployeeDetails()"                       
                          >
                          <el-option :value="0" label="All"></el-option>
                            <el-option
                              v-for="employee in employee_list"
                              :key="employee.id"
                              :label=" employee.first_name +
                                ' ' +
                                (employee.last_name == null
                                  ? ''
                                  : employee.last_name)
                              "
                              :value="employee.id"
                            ></el-option>
                          </el-select>
                      </el-form-item>
                    </div>
                    <div class=" col-sm-4">
                        <el-form-item label="Date Range" prop="date_range">                  
                          <el-date-picker
                             v-model="date_range"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="Start date"
                            end-placeholder="End date"
                            value-format="yyyy-MM-dd"
                            clearable                           
                            >
                          </el-date-picker>
                        </el-form-item>
                    </div>
                    <div class=" col-sm-4">
                      <el-button type="primary" class="search_button" @click="getEmployee()"><i class="el-icon-search" style="color:#fff"></i> Search</el-button>
                      <el-button type="success" class="search_button" @click="downloadTimeSheet()"><i class="el-icon-download" style="color:#fff"></i> Download</el-button>
                      <el-button type="success" class="search_button" @click="downloadTimeSheetExcel()"><i class="fas fa-file-excel" style="color:#fff"></i> Excel</el-button>
                    </div> 
                                   
                 </div>
                </el-form>
            <div class="row mt-3">
                <div class="col-sm-12" v-if="timesheet_all==false">
                    <el-table
                    :data="list"
                    border
                    element-loading-text="Loading"
                    style="width: 100%"
                    v-loading="loading"
                     @selection-change="handleSelectionChange"
                    >
                      <el-table-column
                        type="selection"
                        width="55">
                      </el-table-column>
                    <el-table-column
                        fixed
                        align="center"
                        label="Date"
                        prop="id"                        
                        width="120"
                    >
                        <template slot-scope="scope">
                          {{getDate(scope.row.created_at)}}
                      
                        </template>
                    </el-table-column>

              <el-table-column fixed label="CheckIn ID" prop="employee_check_id"  width="160"  >
                <template slot-scope="scope">
                <span>{{(scope.row.employee_details.employee_check_id) ? scope.row.employee_details.employee_check_id : ""}}</span>
                </template>
              </el-table-column>

                     <el-table-column
                        fixed                       
                        label="Employee Name"
                        prop="id" 
                    >
                        <template slot-scope="scope">
                           <el-tag slot="reference" size="mini">
                             <a href="#" @click.prevent="viewEmployeeSheet(scope.row.employee_details.id)">{{scope.row.employee_details.first_name}} {{scope.row.employee_details.last_name}}</a></el-tag>
                           
                        </template>
                    </el-table-column>

                     <el-table-column
                        fixed
                        align="center"
                        label="Status"
                        prop="id"
                        width="150"
                    >
                        <template slot-scope="scope">
                          <span v-if="scope.row.status =='Check IN'">
                            <span v-if="scope.row.checkin_type =='late'" class="red"><b>{{scope.row.status}}</b></span>
                            <span v-else class="active"><b>{{scope.row.status}}</b></span>
                          </span>
                          <span v-if="scope.row.status =='Check Out'" class="red"><b>{{scope.row.status}}</b></span>                         
                          <span v-if="scope.row.status =='Off'" class="red"><b>Off</b></span>                          
                        </template>
                    </el-table-column>
                     <el-table-column
                        fixed
                        align="center"
                        label="Total Hours"
                        prop="id"
                        width="120"
                    >
                        <template slot-scope="scope">
                          <div v-if="scope.row.total_hours !='0'">{{scope.row.total_hours}}</div>
                          <div v-else>
                              <span v-if="scope.row.status =='Check IN'">
                              <span v-if="scope.row.in_meridiem == 'AM'">                                
                                <span >{{timeCalculation(current_time ,scope.row.actual_time_in)}}</span>                               
                              </span>
                             <span v-if="scope.row.in_meridiem == 'PM'">
                                <span >{{totalCalculationPM(current_time ,scope.row.actual_time_in)}}</span>             
                             </span>
                              </span>
                               
                          </div>
                        </template>
                    </el-table-column>
                    </el-table>
                </div>
                <div class="col-sm-12" >
                <employeeAllDetails v-if="timesheet_all==true" :date_ranges="date_range" :employeeId="employee_id"></employeeAllDetails>
                </div>
            </div>
             <div class="row">
                <div class="col-sm-12">
                  <el-pagination
                    v-if="!loading"
                    style="margin-top: 15px"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="fetchData"
                    :page-count="pagination.last_page"
                    :page-sizes="[50, 100, 250, 500, 1000]"
                    :page-size="pagination.per_page"
                    :current-page.sync="pagination.current_page"
                    :total="pagination.total_rows"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getEmployeeSheet,getEmployeeList,downloadAllEmployeePdf,downloadAllEmployeeExcel} from "@/api/employeeLogin";
import employeeAllDetails from "@/components/employeeCheckIn/employee_all_time_sheet";
import { globalEvents } from '@/utils/globalEvents';
import moment from 'moment-timezone';
const candaTime = moment.tz.setDefault('America/Toronto');
const date = new Date();
export default {
    name: 'employee_time_sheet',
     data() {
    return {
        loading: false,
        tittle:'Employee Time Sheet',
        multipleSelection: [],
        pagination: {
        total_rows: 1,
        current_page: 1,
        last_page: null,
        per_page: 50,
      },
      list : [],
       dateTime: {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
       
      },
      current_time : 0,
      timer:undefined,
      employee_list:[],
      employee_id:0,
      date_range:[],
      timesheet_all:false,
    }
   },
   mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Employees", route: "/employee/list" },
      { title: "Employee Time Sheet" }
    ]);

     globalEvents.$on('selectRow', chnageValue => {
       this.multipleSelection = chnageValue;
        
        }); 
  },
  components:{
    employeeAllDetails
  },
   created() {
    this.getEmployeeList();
    this.fetchData(1);
    const date = new Date();
    let hours = date.getHours();
    let minutes = ('0'+date.getMinutes()).slice(-2);    
    if(hours == 0){
      hours = 12
    }
    this.current_time = hours+' : '+ minutes    
    this.date_range = [this.convert(date),this.convert(date)];   
   },
  methods: {
    getEmployeeDetails(){
        this.fetchData(1);
    },
    getDate(date){
      return candaTime(date).format('DD-MM-YYYY')
    },
    dateClear(){      
        this.date_range = [];
        this.fetchData(1);
    },
    handleSizeChange(per_page) {
      this.pagination.per_page = per_page;
      this.fetchData(1);
    },
   timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return  rhours + ' : ' + rminutes ;
      },
   timeCalculation(time1, time2){ 
        var Time1 = time1
        var Time2 = time2
        var splitTime1= Time1.split(':');
        var splitTime2= Time2.split(':');
        var hour=0;
        var ct_hour = (parseInt(splitTime1[0]))*60+(parseInt(splitTime1[1]))        
        var record_in = (parseInt(splitTime2[0]))*60+(parseInt(splitTime2[1]))
        
        var h = 0;
        if(ct_hour > record_in){
           h = ct_hour - record_in
        }else{
          h = 0
        }

        if(h>=60){  
          hour = this.timeConvert(h);
        }else{
          hour = '0 : '+h
        } 

        if(h > 270){
              var total = h - 30
              hour = this.timeConvert(total);
            }
          return hour;         
      },   
      totalCalculationPM(time1, time2){
       
        var splitTime1= time1.split(':');
        var splitTime2= time2.split(':');
        var hour=0;
       var ct_hour = (parseInt(splitTime1[0]))*60+(parseInt(splitTime1[1]))
        if(parseInt(splitTime1[0]) >13){
           ct_hour = (parseInt(splitTime1[0])-12)*60+(parseInt(splitTime1[1])) 
        }
               
        var record_in = (parseInt(splitTime2[0]))*60+(parseInt(splitTime2[1]))
        
        var h = 0;
        if(ct_hour > record_in){
           h = ct_hour - record_in
        }else{
          h = 0
        }      
       
        if(h>=60){  
          hour = this.timeConvert(h);
        }else{
          hour = '0 : '+h
        }       
          if(h > 270){
            var total = h - 30
             hour = this.timeConvert(total);
          }     
            return hour;       
      },   
     handleSelectionChange(val) {
        this.multipleSelection = val;
        console.log(this.multipleSelection);
      },
     beforeMount() {
        this.timer = setInterval(this.fetchData(1), 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },

  getEmployee(){
    this.timesheet_all = false;    
      var start_date = '';
      var end_date = '';
     
     if(this.date_range){
        for(var i=0;i<this.date_range.length;i++){
           start_date = this.date_range[0];
           end_date = this.date_range[1];
        }
      }
      if(start_date != end_date){
          globalEvents.$emit('changeTable', this.date_range);
          this.$emit('close');
          this.timesheet_all = true;
      }else{
        this.timesheet_all = false;
        this.date_range = [start_date,end_date]; 
        this.fetchData(1);
      }

    
    
  },
  convert(str) {

    return candaTime(str).format('YYYY-MM-DD')
        // var date = new Date(str),
        // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        // day = ("0" + date.getDate()).slice(-2);
        // return [date.getFullYear(), mnth, day].join("-");candaTime(date).format('YYYY-MM-DD')
    },
    fetchData(p) {
      this.loading = true;
      let params = { page: p };
    
      if (this.pagination.per_page) {
        params.per_page = this.pagination.per_page;
      }

      if(this.employee_id){
        params.employee_id = this.employee_id
      }
     
      if(this.date_range){
        for(var i=0;i<this.date_range.length;i++){
           params.start_date = this.date_range[0];
           params.end_date = this.date_range[1];
        }
      }
   
      getEmployeeSheet(params).then((response) => {            
        this.list = response.data.data;    
              
        this.pagination.current_page = response.data.data.current_page;
        this.pagination.last_page = response.data.data.last_page;
        this.pagination.total_rows = response.data.data.total;
        this.loading = false;
        this.$set(this.list, "_showDetails", true);
      });
      

    },
   
    downloadTimeSheet(){
      this.loading = true;
          let params = { page: 1 };
         
              if(this.employee_id){
                params.employee_id = this.employee_id
              }
              if(this.date_range){
                for(var i=0;i<this.date_range.length;i++){
                  params.start_date = this.date_range[0];
                  params.end_date = this.date_range[1];
                }
              }
              params.multipleSelection = this.multipleSelection
              
              
              downloadAllEmployeePdf(params).then(response => {
                this.loading=true;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'employeeTimeSheet.pdf');
                document.body.appendChild(link);
                link.click();
                this.loading = false;
              }).catch(err => {
                console.log(err);
                this.loading = false;
              });
    },
     downloadTimeSheetExcel(){
          this.loading = true;
          let params = { page: 1 };
            if(this.employee_id){
                  params.employee_id = this.employee_id
                }
              if(this.date_range){
                for(var i=0;i<this.date_range.length;i++){
                  params.start_date = this.date_range[0];
                  params.end_date = this.date_range[1];
                }
              }
              params.multipleSelection = this.multipleSelection
              
              downloadAllEmployeeExcel(params).then(response => {
                this.loading=true;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'employeeTimeSheet.xlsx');
                document.body.appendChild(link);
                link.click();
                this.loading = false;
              }).catch(err => {
                console.log(err);
                this.loading = false;
              });
    },
    getEmployeeList(){
      this.loading = true;
      getEmployeeList().then((response) => {       
          this.loading = false;
          this.employee_list = response.data.data;
           
        });
    },
    viewEmployeeSheet(employee_id){
        this.$router.push({name:"employee_time_sheet_details", params: {employeeId: employee_id}})
    }
  }
}
</script>

<style scoped>

.el-form-item__label{
    font-size: 20px !important;
}
.date_field{
    width: 145px;
}
.label_field{
    font-size: 11px;
    text-align: left;
    color: #000000;
    font-weight: 600;
    margin-bottom: 5px;
}
.field_input{
    -webkit-appearance: none;
    background-color: #F8F8F8;
    background-image: none;
    border-radius: 3px;
    border: 1px solid #d4ccdc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    font-size: 11px;
    height: 26px !important;
    line-height: 30px;
    outline: 0;
    padding: 0 8px;
    -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;   
    -webkit-box-shadow: 0 0 1px 1px inset #f2f1f9;
    box-shadow: 0 0 1px 1px inset #f2f1f9;
   
}
.el-form .el-input__inner{
    font-size: 10px !important;
    display: flex !important;
}
.el-date-editor .el-range-separator{
  width: 0% !important;
}

#product th{
    font-size: 11px !important;
    padding-left: 10px;
}
#product td{
    font-size: 11px !important;
    padding-left: 10px;
    height: 22px;
    overflow: auto;
}
 #product tr:first-child {
    background-color:#EDEDED;
    border:1px solid #EDEDED;
}
.product_table_hd{
     background-color: #EDEDED !important;
     border:1px solid #EDEDED;
}

#product tr:nth-child(odd) {background-color: #EFF8FC;}
.el-input__suffix{
    margin-top: 5px !important;
}
.el-select .el-input .el-select__caret{
    margin-top: 7px !important;
}

.select_field {
   -webkit-appearance: none;
    background-color: #F8F8F8;
    background-image: none;
    border-radius: 3px;
    border: 1px solid #d4ccdc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    font-size: 11px;
    height: 26px !important;
    line-height: 30px;
    outline: 0;
    padding: 0 8px;
    -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;   
    -webkit-box-shadow: 0 0 1px 1px inset #f2f1f9;
    box-shadow: 0 0 1px 1px inset #f2f1f9;
}
.el-form .el-form-item .el-form-item__label{
  font-size: 11px !important;
}
.el-select{
  widows: 175px !important;
}
.search_button{
  padding: 6px;
    color: white;
    margin-top: 23px;
    padding-left: 10px;
    padding-right: 10px;
}
</style>

